import { type Ref, ref } from 'vue';
import Api from '@/api/configuration';
import { showErrorMessage, showSuccessMessage } from '@/helpers/notifications';
import { ConfigOptionModel } from '@/models/configuration';

export function useConfiguration(): {
  isProcessing: Ref<boolean>
  configuration: Ref<ConfigOptionModel[]>
  getConfig: () => Promise<void>
  setConfigOption: (option: ConfigOptionModel) => Promise<void>
} {
  const isProcessing: Ref<boolean> = ref(false);
  const configuration: Ref<ConfigOptionModel[]> = ref([]);

  // GET LIST
  async function getConfig(): Promise<void> {
    try {
      isProcessing.value = true
      const { data } = await Api.getConfig();
      configuration.value = data.data.map((option: ConfigOptionModel) => {
        if (option.type === 'toggle') {
          option.value = option.value != '0';
        }
        return option;
      })
    }
    catch {
      showErrorMessage(null, 'Failed to load the settings configuration');
      configuration.value = []
    }
    finally {
      isProcessing.value = false
    }
  }

  // SET Option
  async function setConfigOption(option: ConfigOptionModel): Promise<void> {
    try {
      isProcessing.value = true
      let value = option.value
      if (option.type === 'toggle')
        value = option.value ? '1' : '0';

      await Api.setConfigOption(option.id, { value });
      showSuccessMessage('The settings option is saved');
    }
    catch {
      showErrorMessage(null, 'Error saving a settings option');
      // switch back
      if (option.type === 'toggle') {
        const opt = configuration.value.find(o => o.id === option.id);
        if (opt)
          opt.value = !opt.value;
      }
    }
    finally {
      isProcessing.value = false
    }
  }

  return {
    isProcessing,
    configuration,
    getConfig,
    setConfigOption,
  };
}
