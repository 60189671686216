<script setup lang="ts">
import { computed, onMounted, ref, type Ref, watch } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useAuthStore } from '@/store'
import { useRouter } from 'vue-router'
import { useTasksStore } from '@/store/currentTasks'
import CoreAutoCategoriesApi from "@/api/core/auto-categories";
import { showSuccessMessage } from "@/helpers/notifications";
import { useConfiguration } from "@/composables/configuration/use-configuration";

const tasksStore = useTasksStore();
const auth = useAuthStore()
const router = useRouter()
const autoCategorization: Ref<boolean> = ref(false)
const { configuration, getConfig, setConfigOption } = useConfiguration()
const user = computed(() => ({ name: auth.getName, company: auth.getCompany, avatar: auth.getAvatar }))
const initials = computed(() => {
  const name = user.value.name || '';
  const [firstWord, secondWord] = name.split(' ');
  return firstWord ? (secondWord ? `${firstWord.charAt(0)}${secondWord.charAt(0)}` : firstWord.charAt(0)) : '-';
});

const taskCount = computed(() => tasksStore.getTaskCount);
const taskBlacklistCount = computed(() => tasksStore.getTaskBlacklistCount);

function menuHandle(command: string): void {
  if (command === 'logout') {
    auth.logout(router)
  }
}

async function getAutoCategorization() {
  if (!auth.isLogin) return

  try {
    const { data } = await CoreAutoCategoriesApi.getAutoCategory()
    autoCategorization.value = data.auto_category === 1

  } catch (e: any) {
    console.log(e)
  }
}

async function changeAutoCategorization() {
  try {
    await CoreAutoCategoriesApi.setAutoCategory(autoCategorization.value)
    showSuccessMessage('Option value automatic categorization saved')

  } catch (e: any) {
    console.log(e)
    autoCategorization.value = !autoCategorization.value
  }
}

watch(
  () => auth.isLogin,
  value => {
    if (value) {
      getAutoCategorization();
      getConfig();
    }
  }
)

onMounted(() => {
  setInterval(() => {
    tasksStore.getParseWords();
  }, 15000);
  tasksStore.getParseWords()

  if (auth.isLogin) {
    getAutoCategorization();
    getConfig();
  }
})
</script>

<template>
  <div class="header">
    <router-link to="/">
      <img src="/media/logo-name-slogan-color.svg" alt="Logo" class="header__logo" />
    </router-link>

    <div v-if="auth.isLogin" class="header__info">
      <div class="header__info-counts">
        <span class="header__info-value">
          Active scraping tasks: <b>{{ taskCount }}</b>
        </span>
        <span class="header__info-value header__info-value_blacklist">
          Active blacklist scraping tasks: <b>{{ taskBlacklistCount }}</b>
        </span>
      </div>

      <el-dropdown @command="menuHandle" trigger="click" popper-class="header-menu" :hide-on-click="false">
        <span class="el-dropdown-link">
          <span class="trigger__wrapper">
            <span v-if="!!user.avatar.trim()" class="trigger__logo">
              <img class="trigger__avatar" :src="user.avatar" :alt="initials">
            </span>
            <span v-else class="trigger__logo">
              {{ initials }}
            </span>
            <span class="trigger__info">
              <span class="trigger__name">{{ user.name || '-' }}</span>
              <span class="trigger__company">{{ user.company || '-' }}</span>
            </span>
            <svg-icon width="10" height="7" icon="triangle" />
          </span>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item disabled>
              <span>Options</span>
            </el-dropdown-item>

            <el-dropdown-item>
              <el-checkbox
                v-model="autoCategorization"
                class="custom-checkbox"
                size="large"
                @change="changeAutoCategorization"
              >
                Auto-categorization
              </el-checkbox>
            </el-dropdown-item>

            <el-dropdown-item v-for="option in configuration" :key="option.id">
              <template v-if="option.type === 'toggle'">
                <el-checkbox
                  v-model="option.value"
                  class="custom-checkbox"
                  size="large"
                  @change="setConfigOption(option)"
                >
                  {{ option.name }}
                </el-checkbox>
              </template>
            </el-dropdown-item>

            <el-dropdown-item command="logout" divided>
              <svg-icon width="20" height="20" icon="logout2" />
              <span>Log Out</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 70px;
  padding: 24px;
  background-color: var(--color-white);
  box-shadow: var(--header-shadow);
  z-index: 10;

  &__logo {
    width: 284px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__info-counts {
    display: flex;
    flex-direction: column;
  }

  &__info-value {
    color: var(--color-ebony-clay);
    font-size: 14px;
    white-space: nowrap;

    &_blacklist {
      color: var(--color-boulder);
    }
  }
}

.trigger {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 200px;
    cursor: pointer;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 32px;
    height: 32px;
    background-color: var(--color-carnation);
    border-radius: 50%;
    color: var(--color-black);
    font-size: 16px;
    font-weight: 600;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: auto;
  }

  &__name,
  &__company {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__name {
    color: var(--color-woodsmoke);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.1px;
  }

  &__company {
    color: var(--color-mid-gray);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.1px;
  }
}

:deep .el-dropdown-menu__item {
  display: flex;
  gap: 12px;
  min-width: 240px;
  max-width: 260px;
  font-family: var(--main-font);

  span {
    max-width: calc(100% - 34px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

:deep {
  .el-dropdown-menu__item:not(.is-disabled):focus {
    background-color: var(--el-fill-color-light);
    color: var(--color-mako);
  }
}

</style>
