import ApiService from '@/api/apiService'
import type { AxiosResponse } from 'axios'

class ConfigurationApi {
  static readonly url = '/core/config'

  public static getConfig(): Promise<AxiosResponse> {
    return ApiService.get(`${this.url}`);
  }

  public static setConfigOption(id: number, data: object): Promise<AxiosResponse> {
    return ApiService.put(`${this.url}/${id}`, data);
  }
}

export default ConfigurationApi
